export function removeMessage(chatMessage) {
  let messageId = chatMessage.getAttribute("data-message-id");
  let chatRoomId = $("#messages-container").data("chat-room-id");

  $(".remove-message").on("click", function () {
    $(".modal-remove-message").css("display", "flex");
    console.log("App: Microphone Off");
    $(".context-menu").css("display", "none");
    $(".modal-remove-message").attr("data-message-id", messageId);

    $(".remove-message").off("click");
  });

  $(".btn-remove").on("click", function() {
    $(this).prop("disabled", true);
    $.ajax({
      url: `/chat_plugin/chat_rooms/${chatRoomId}/chat_messages/${messageId}/remove_message`,
      type: "DELETE",
      remote: true,
      success: function() {
        $(".modal-remove-message #success-alert").show();
        
        setTimeout(function() {
          $(".modal-remove-message").hide();
          console.log("App: Microphone On");
          $(".btn-remove").off("click");

          $(".modal-remove-message #success-alert").css("display", "none");
        }, 1000);
      },
      error: function() {
        $(".modal-remove-message #error-warning").show();
        
        setTimeout(function() {
          $(".modal-remove-message").hide();
          console.log("App: Microphone On");
          $(".btn-remove").off("click");

          $(".modal-remove-message #error-warning").css("display", "none");
        }, 1000);
      }
    });
    $(this).prop("disabled", false);
    $(".btn-remove").off("click");
    $(".modal-remove-message .btn-back").off("click");
  });

  $(".modal-remove-message .btn-back").on("click", function () {
    $(".modal-remove-message").css("display", "none");
    console.log("App: Microphone On");
    $(".modal-remove-message .btn-back").off("click");
    $(".btn-remove").off("click");
  });
}
