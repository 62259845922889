import { Controller } from "@hotwired/stimulus";
import {
  isIOS,
  isAndroid,
  removeImages,
  removeVideos,
  removeFiles,
  removeAttachments,
  valueTimer
} from "../channels/chat_utils";
import RecordRTC from "recordrtc";

// Connects to data-controller="voice-messages"
export default class extends Controller {
  connect() {
    if (!isIOS() && !isAndroid()) {
      this.audio();
    }
  }

  audio() {
    let record = document.getElementById("audio-record-button");
    let uploadAttachments = document.getElementById("file-input"); 
    let recording = false;
    const options = { 
      type: "audio",
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 2,
      mimeType: "audio/wav",
    };

    if (navigator.mediaDevices.getUserMedia) {
      const constraints = { audio: true };

      let onSuccess = function(stream) {
        const mediaRecorder = new RecordRTC(stream, options);
        let iconRecording = document.querySelector(".recording");
        let timeRecording = document.querySelector(".timer");
        let timeInterval;

        record.onclick = function(event) {
          event.preventDefault();

          if (recording) {
            mediaRecorder.stopRecording(function (blobURL) {
              let blob = mediaRecorder.getBlob();
              let container = new DataTransfer();
              let removeUpload = document.querySelector(".remove-upload");
              let file = new File([blob], "audio-message.wav", {
                type: options.mimeType,
                lastModified: new Date().getTime(),
              });
    
              iconRecording.style.display = "none";
              timeRecording.style.display = "none";
    
              container.items.add(file);

              uploadAttachments.files = container.files;
              uploadAttachments.dispatchEvent(new Event("change"));
    
              $(".attachments-preview").css("display", "block");
              console.log("App: Microphone Off");
              $(".floating-popup").css("display", "block");
              $(".audio-preview").css("display", "block");
              $(".audio-preview").attr("src", blobURL);
              removeUpload.style.display = "block";
              $(".file-preview-container").css("display", "none");
    
              removeImages();
              removeVideos();
              removeFiles();
              removeAttachments();
            });

            // Clean timer
            document.querySelector(".seconds").innerHTML="00";
            document.querySelector(".minutes").innerHTML="00";
            clearInterval(timeInterval);

          } else {
            mediaRecorder.startRecording();
            iconRecording.style.display = "block";
            timeRecording.style.display = "flex";
            
            // Set timer
            let time = 0;
            timeInterval = setInterval(function() {
              document.querySelector(".seconds").innerHTML = valueTimer(++time%60);
              document.querySelector(".minutes").innerHTML = valueTimer(parseInt(time/60,10));
            }, 1000);
          }

          recording = !recording;
        };
      };

      let onError = function (err) {
        console.log("The following error occured: " + err);
      };

      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
    } else {
      console.log("getUserMedia not supported on your browser!");
    }
  }
}
