import { reactionMessage } from "./reaction";
import { reportMessage } from "./report";
import { removeMessage } from "./remove";
import { resendMessage } from "./resend";
import { replyMessage } from "./reply";

//
// Set actions to all messages in chat
//
export function setActionsInMessages(chatMessages) {
  for (let item of chatMessages) {
    let dropdownContextMenu = item.querySelector(".dropdown-context-menu");
    let contextMenu = item.querySelector(".context-menu");
    let numberReactions = item.querySelector(".reactions");

    if (numberReactions !== null) {
      if (numberReactions.innerHTML > 0) {
        item.querySelector(".chat-reactions").style.display = "flex";
        item.querySelector(".chat-reactions").style.visibility = "visible";
      }
    }

    // Only in not deleted messages!
    let deletedMessage = item.getAttribute("data-deleted-message");
    if (deletedMessage === "") {
      if (item.getAttribute("listener") === "true") { break; }
      dropdownContextMenu.addEventListener("click", function() {
        // Change position of context menu when is the
        // context menu of the last message at bottom in screen.
        let topPositionDropdownArrow = this.getBoundingClientRect().top;
        let viewportHeight = window.innerHeight;
        let directChatMsg = this.closest(".direct-chat-msg");
        let isAImage = this.closest(".chat-text").classList.contains("image");
        let isAVideo = this.closest(".chat-text").classList.contains("video");
        let isALargeMessage = this.closest(".chat-text").offsetHeight > 200;

        if (directChatMsg.classList.contains("left")) {
          contextMenu.style.right = "-8px";
        }

        if (topPositionDropdownArrow >= (viewportHeight / 2)) {
          if (isAImage || isAVideo || isALargeMessage) {
            contextMenu.style.bottom = "1px";
            contextMenu.style.top = null;
          } else {
            if (directChatMsg.classList.contains("left")) {
              contextMenu.style.top = "-8rem";
            } else {
              contextMenu.style.top = "-10rem";
            }
          }
        } else {
          contextMenu.style.top = "100%";
          contextMenu.style.bottom = null;
        }

        if (contextMenu.style.display === "none" || contextMenu.style.display === "") {
          contextMenu.style.display = "block";

          reportMessage(item);
          removeMessage(item);
          reactionMessage(item);
          resendMessage(item);
          replyMessage(item);
        } else {
          contextMenu.style.display = "none";
        }
      });
      item.setAttribute("listener", "true");
    }
  }

  // Hide context menu when click anywhere in screen
  if ($(".context-menu") !== undefined) {
    $("#messages-container").on("mousedown", function (e) {
      if ((e.target.class == "context-menu")) {
        return;
      }
      if (($(e.target).closest(".context-menu").length)) {
        return;
      }
  
      $(".context-menu").css("display", "none");
    });
  }
}
