function manageReaction(reaction, chatMessage, reactionType) {
  let messageId = chatMessage.getAttribute("data-message-id");
  let chatRoomId = $("#messages").data("chat-room-id");
  let reactions = chatMessage.querySelectorAll(".reaction-menu span");

  if (reaction.classList.contains("selected")) {
    reactions.forEach(function(element) {
      element.classList.remove("no-selected");
      element.classList.remove("selected");
    });
  } else {
    reactions.forEach(function(element) {
      element.classList.add("no-selected");
    });

    reaction.classList.add("selected");
    reaction.classList.remove("no-selected");
  }
  
  $.ajax({
    url: `/chat_plugin/chat_rooms/${chatRoomId}/chat_messages/${messageId}/reaction`,
    data: {
      reaction_type: reactionType
    },
    type: "POST",
    remote: true,
    success: function () {
      console.log("success");
    }
  });
}

export function reactionMessage(chatMessage) {
  let contextMenu = chatMessage.querySelector(".context-menu");
  let reactionButtons = [".like", ".celebrate", ".funny", ".love", ".dislike", ".curious"];

  contextMenu.addEventListener("click", function(e) {
    if (e.target.matches(reactionButtons)) {
      e.stopImmediatePropagation();
      let reaction = e.target;
      let reactionType = reaction.className.split(" ")[0].trim();

      // Redifine elements inside a document click because previous event use the last element used before
      chatMessage = reaction.closest(".direct-chat-msg");
      contextMenu = reaction.closest(".context-menu");

      manageReaction(reaction, chatMessage, reactionType);
      contextMenu.style.display = "none";
    }
  });
}
