export function replyMessage(chatMessage) {
  let messageId = chatMessage.getAttribute("data-message-id");
  let messageAuthorName = chatMessage.getAttribute("data-author-fullname");
  let messageBody = chatMessage.getAttribute("data-message-body");

  $(".reply-message").on("click", function () {
    $(".context-menu").css("display", "none");
    $(".box-footer .input-group").css("display", "block");
    $(".main-form").css("border-left", "1px solid #b6b6b6");
    $(".main-form").css("border-right", "1px solid #b6b6b6");

    $(".send-message-form").css("background-color", "#fff");
    
    $(".reply-message-info").css("display", "block");
    $(".reply-message-content").css("display", "flex");

    $(".reply-message-content .author").html(messageAuthorName);
    $(".reply-message-content .message").html(`${messageBody.replace(/(<([^>]+)>)/gi, "").slice(0, 20)}...`);

    $("#message-body").focus();

    const newMessage = document.querySelector("#message-body");
    newMessage.setAttribute("data-parent-id", messageId);

    // Cancel reply
    $(".cancel-reply").on("click", function () {
      $(".reply-message-info").css("display", "none");
      $(".reply-message-content").css("display", "none");
      $(".main-form").css("border", "none");
      newMessage.removeAttribute("data-parent-id");
    });
  });
}
