/**
 * Check if is iOS
 */
export function isIOS() {
  return /(iPhone)/g.test(navigator.userAgent);
}

/**
 * Check if is Android
 */
export function isAndroid() {
  return /(android)/i.test(navigator.userAgent);
}

/**
 * Remove attachment when click in back button in preview attachment screen.
 */
export function removeAttachments() {
  let removeUpload = document.querySelector(".remove-upload");

  document.querySelector(".remove-upload i").addEventListener("click", function() {
    removeUpload.style.display = "none";
    $(".attachments-preview").css("display", "none");
    console.log("App: Microphone On");
    $(".floating-popup").css("display", "none");

    removeImages();
    removeVideos();
    removeFiles();
  
    // For audios
    $(".audio-preview").attr("src", "");
    $(".audio-preview").css("display", "none");
  
    document.getElementById("file-input").value = "";
    $("#message-body").removeAttr("disabled");

    // New to direct uploads
    $("#file-input").removeAttr("disabled");
    $("#file-input").val("");
    $(".direct-upload").css("display", "none");
    $("#form-upload-file input:hidden[name='uploadFile']").val("");
    $("#form-upload-file input:hidden[name='uploadFile']").not($("#file-input")).remove();

    $("#span-btn-send-message").show();
  });
}

/**
 * Convert number of bytes into human readable format
 *
 * @param {number} bytes Number of bytes to convert
 * @param {number} decimals Number of digits after the decimal separator
 * @return {string} size with units. 
 */
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Set file and show it in preview attachments.
 *
 * @param {file} input The input in attachment file button.
 */
export function readURL(input) {
  $("#message-body").prop("disabled", true);

  if (input.files && input.files[0]) {
    let reader = new FileReader();
    let fileType = input.files[0].type.split("/")[0];
    let removeUpload = document.querySelector(".remove-upload");
    removeUpload.style.display = "block";
    $("#span-btn-send-message").hide();
    $(".remove-upload").show();

    if (fileType === "image") {
      reader.onload = function (e) {
        showImages(e, reader);
        removeVideos();
        removeFiles();
      };
      reader.readAsDataURL(input.files[0]);
    } else if(fileType === "video") {
      reader.onload = function (e) {
        showVideos(e);
        removeImages();
        removeFiles();
      };
      reader.readAsDataURL(input.files[0]);

    } else {
      showFiles(input.files[0]);
      removeImages();
      removeVideos();
    }
    removeAttachments();
  }
}

// Show/hide images in preview attachments
function showImages(e, reader) {
  $(".attachments-preview").css("display", "block");
  console.log("App: Microphone Off");
  $(".floating-popup").css("display", "block");
  $(".floating-popup").css("background-image", `url(${reader.result})`);
  $("#img_prev").attr("src", e.target.result);

  let showImagePreview = document.querySelector(".show-image-preview");
  showImagePreview.style.display = "block";
  showImagePreview.style.backgroundImage = `background-image: url(${e.target.result});`;
}
export function removeImages() {
  $("#img_prev").attr("src", "");
  $(".floating-popup").css("background-image", "url()");
}

// Show/hide videos in preview attachments
function showVideos(e) {
  $(".attachments-preview").css("display", "block");
  console.log("App: Microphone Off");
  $(".floating-popup").css("display", "block");
  $(".video-preview").css("display", "block");
  $(".video-preview").attr("src", `${e.target.result}#t=0.5`);
}
export function removeVideos() {
  $(".video-preview").css("display", "none");
  $(".video-preview").attr("src", "");
}

// Show/hide files in preview attachments
function showFiles(file) {
  $(".attachments-preview").css("display", "block");
  console.log("App: Microphone Off");
  $(".floating-popup").css("display", "block");
  $(".floating-popup").css("background-image", "url()");
  $(".file-preview-container").css("display", "flex");
  $(".file-preview").css("display", "block");
  $(".file-preview-name").css("display", "block");
  $(".file-preview-name").html(file.name);
  $(".file-preview-size").html(formatBytes(file.size));
}
export function removeFiles() {
  $(".file-preview-container").css("display", "none");
  $(".file-preview").css("display", "none");
  $(".file-preview-name").css("display", "none");
  $(".file-preview-name").html("");
  $(".file-preview-size").html("");
}

export function valueTimer(value) {
  return value > 9 ? value : "0" + value;
}

// Go to message with an anchor (Example: 15 -> Will search the element with id "id-message-15")
export function goToMessageWithAnchor(idMessage) {
  let strIdMessage = `id-message-${idMessage}`;
  goToAnchorWithIdElement(strIdMessage);
}

// Go to anchor with id of element without changing the url (example: id of div element)
export function goToAnchorWithIdElement(strIdElement) {
  let elemWithId = document.getElementById(strIdElement);
  if (elemWithId) {
    // smooth method makes scrolling slower. Without behavior is faster. 
    // elemWithId.scrollIntoView({ block: 'start', behavior: 'smooth' });
    elemWithId.scrollIntoView({ block: "start" });
  }
}

// Update links to avoid errors in apps that don't support target options
export function updateLinksOptions() {
  if (isAndroid()) {
    $("a").removeAttr("target");
  }
}