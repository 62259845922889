import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import { setActionsInMessages } from "../channels/message_actions/context_menu";
import { setActionInAvatars } from "../channels/chat_plugin/contact_profile";
import { updateLinksOptions } from "../channels/chat_utils";

export default class AutoPagination extends Controller {
  options = {
    threshold: 1
  };

  static messagesContainer;
  static topMessage;
  static setPagination = false;
  static scrollArea;

  static values = {
    withPagination: Boolean
  };

  connect() {
    useIntersection(this, this.options);

    AutoPagination.resetDateBlocks();
    AutoPagination.setDates();
    AutoPagination.scrollArea = document.getElementById("messages-container");
    setActionsInMessages($("#messages-container")[0].querySelectorAll(".direct-chat-msg"));
    setActionInAvatars(document.querySelectorAll(".avatar"));
    updateLinksOptions();
  }

  appear() {
    if (document.querySelector(".spinner")) {
      document.querySelector(".spinner").style.display = "block";
    }
    AutoPagination.scrollArea.setAttribute("data-scroll-height", AutoPagination.scrollArea.scrollHeight);

    setTimeout(()=> {
      this.element.click();
      if (document.querySelector(".spinner")) {
        document.querySelector(".spinner").style.display = "none";
      }
    }, 550);
  }
  
  disconnect() {
    setActionsInMessages($("#messages-container")[0].querySelectorAll(".direct-chat-msg"));
  }

  static setDates() {
    AutoPagination.messagesContainer = document.getElementById("messages-container");
    AutoPagination.messages = AutoPagination.messagesContainer.querySelectorAll(".direct-chat-msg");
    let firstMessage;

    for (let i = 0; i < AutoPagination.messages.length; ++i) {
      if (i == 0) {
        firstMessage = AutoPagination.messages[i];
      }

      let currentMessage = AutoPagination.messages[i];
      let previousMessage = i === 0 ? firstMessage : AutoPagination.messages[i - 1];

      if (currentMessage.getAttribute("data-date") !== previousMessage.getAttribute("data-date")) {
        AutoPagination.newDateBlock(currentMessage, currentMessage.getAttribute("data-date"));
      } else if (currentMessage == previousMessage) {
        AutoPagination.newDateBlock(currentMessage, currentMessage.getAttribute("data-date"));
      }
    }
  }

  static newDateBlock(message, date) {
    const options = { month: "short", day: "numeric" };
    const formatDate = new Date(date).toLocaleDateString("es-ES", options);
  
    const block = document.createElement("div");
    block.className = "messages-date-block";

    let dateBlock =
      `<div class='messages-date'>
        <div class='group-date'>
          ${formatDate}
        </div>
      `;

    block.innerHTML = dateBlock;
    message.parentNode.insertBefore(block, message);
  }

  static resetDateBlocks() {
    const dateBlocks = document.querySelectorAll(".messages-date-block");
      
    dateBlocks.forEach(dateBlock => {
      dateBlock.remove();
    });
  }

  // Go to message position in window with anchor element
  static goToMessageWithAnchorPosition(page, idMessage) {
    // Page 2 is loader automatically when load the first page, we fix this if it is the second page.
    //- Page one go to last message unread, but if load page 2 too, it will execute this function (we fix this if it is the page 2)
    if (page != 2) {
      let strIdElement = `id-message-${idMessage}`;
      let elemMessage = document.getElementById(strIdElement);
      if (elemMessage) {
        elemMessage.scrollIntoView({ block: "start" });
      }
    }
  }
}

window.autopagination = AutoPagination;
