let globalChatMessage;

function removeModal() {
  $(".contact").off("click");
  $(".resend-message").off("click");

  let boxBody = document.querySelector(".box-body");
  if (document.querySelector(".contacts-modal") !== null) {
    boxBody.parentNode.removeChild(document.querySelector(".contacts-modal"));
  }

  console.log("App: Microphone On");
}

export function resendMessage(chatMessage) {
  globalChatMessage = chatMessage;

  let chatRoomId = $("#messages").data("chat-room-id");
  let btnSend = document.querySelector(".btn-send");

  $(".resend-message").on("click", function() {
    console.log("App: Microphone Off");
    btnSend.style.display = "none";
    $.ajax({
      url: `/chat_plugin/chat_rooms/${chatRoomId}/contacts`,
      type: "GET",
      remote: true
    });

    $(this).prop("disabled", true);
    $(".context-menu").css("display", "none");
  });
}

document.addEventListener("click", function(e) {
  let btnSend = document.querySelector(".btn-send");

  if (e.target.matches(".btn-back")) {
    if (e.target.parentElement.parentElement.classList.contains("modal-resend-message")) {
      removeModal();
      btnSend.style.display = "block";
    }
  }

  if (e.target.closest(".contact")) {
    if (e.target.parentElement.classList.contains("contacts") || 
        e.target.parentElement.classList.contains("contact") ||
        e.target.parentElement.classList.contains("groups")) {
      $(this).prop("disabled", true);
      e.stopImmediatePropagation();

      let chatRoomId = "";
      let contactId = "";
      let messageId = globalChatMessage.getAttribute("data-message-id");

      if (e.target.getAttribute("data-chat-room-id") !== null || e.target.parentElement.getAttribute("data-chat-room-id") !== null) {
        if (e.target.getAttribute("data-chat-room-id") !== null) {
          chatRoomId = e.target.getAttribute("data-chat-room-id");
        } else {
          chatRoomId = e.target.parentElement.getAttribute("data-chat-room-id");
        }
      } else {
        if (e.target.getAttribute("data-contact-id") !== null) {
          contactId = e.target.getAttribute("data-contact-id");
        } else {
          contactId = e.target.parentElement.getAttribute("data-contact-id");
        }

        // Get the chat room between contact and current app user
        $.ajax({
          url: "/ws/v1/chat_rooms",
          type: "POST",
          data: {
            "app_user_id": contactId
          },
          async: false,
        }).done(function(res) {
          chatRoomId = res.data.chat_room.id;
        });
      }

      $.ajax({
        url: `/ws/v1/chat_messages/${messageId}/resend`,
        type: "POST",
        data: {
          "chat_room_id": chatRoomId
        },
        success: function(res) {
          if (res.data) {
            $(".modal-resend-message #success-alert").show();
            setTimeout(function() {
              $(".modal-resend-message #success-alert").hide(); 
              $(".modal-resend-message").css("display", "none");
              btnSend.style.display = "block";
              console.log("App: Microphone On");
            }, 1500);
          } else if (res.errors ) {
            $(".modal-resend-message #error-alert").show();
            setTimeout(function() {
              $(".modal-resend-message #error-alert").hide(); 
              $(".modal-resend-message").css("display", "none");
              btnSend.style.display = "block";
              console.log("App: Microphone On");
            }, 1500);
          }
        },
      });
    }
    $(".contact").off("click");
  }
});
