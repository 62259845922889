// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "../controllers";

import "bootstrap";
require("@rails/ujs").start();
require("turbolinks").start();
import "../stylesheets/application";

document.addEventListener("turbolinks:load", () => {
  $("[data-toggle='tooltip']").tooltip();
  $("[data-toggle='popover']").popover();
});

require("admin-lte");
import "@fortawesome/fontawesome-free/js/all";
