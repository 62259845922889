document.addEventListener("DOMContentLoaded", function() {
  const avatars = document.querySelectorAll(".item");
  setActionInAvatars(avatars);
});

export function setActionInAvatars(avatars) {
  for (let item of avatars) {
    item.onclick = function () {
      let contactProfileCard = item.querySelector(".contact-profile-card");
      let btnSend = document.querySelector(".btn-send");
      let messagesContainer = document.querySelector("#messages-container");

      if (contactProfileCard.style.display === "none" || contactProfileCard.style.display === "") {
        console.log("App: Microphone Off");
        contactProfileCard.style.display = "flex";
        btnSend.style.zIndex = 0;
        messagesContainer.style.overflowY = "hidden";
      } else {
        console.log("App: Microphone On");
        contactProfileCard.style.display = "none";
        btnSend.style.zIndex = 6;
        messagesContainer.style.overflowY = "scroll";
      }
    };
  }
}
