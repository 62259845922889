export function reportMessage(chatMessage) {
  chatMessage.querySelector(".report-message").style.display = "flex";
  let messageId = chatMessage.getAttribute("data-message-id");
  let reason = "";

  $(".report-message").on("click", function () {
    $(".modal-report-message").css("display", "flex");
    $(".context-menu").css("display", "none");
    console.log("App: Microphone Off");

    $(".modal-report-message").attr("data-message-id", messageId);
    $(".report-message").off("click");
  });

  $(".btn-report").on("click", function () {
    let chat_room_id = $("#messages-container").data("chat-room-id");
    $(this).prop("disabled", true);
    $.ajax({
      url: `/ws/v1/reports/${messageId}/message`,
      type: "POST",
      data: {
        "chat_room_id": chat_room_id,
        "reason": reason
      },
      success: function (res) {
        if (res.data) {
          $(".modal-report-message #success-alert").show();

          setTimeout(function() {
             $(".modal-report-message #success-alert").hide();
             $(".modal-report-message").css("display", "none");
             console.log("App: Microphone On");
          }, 2000);
        } else if (res.errors) {
          $(".modal-report-message #error-alert").show();

          setTimeout(function() {
             $(".modal-report-message #error-alert").hide();
             $(".modal-report-message").css("display", "none");
             console.log("App: Microphone On");
          }, 2000);
        }
      },
    });

    $(".btn-report").off("click");
    $(".modal-report-message .btn-back").off("click");

    $(this).prop("disabled", false);
  });

  $("#report_type_report_type").on("change", function () {
    reason = $(this)[0].value;
  });

  $(".modal-report-message .btn-back").on("click", function() {
    $(".modal-report-message").css("display", "none");
    console.log("App: Microphone On");

    $(".modal-report-message .btn-back").off("click");
    $(".btn-report").off("click");
  });
}
